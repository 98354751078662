var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal" }, [
    _c("div", { staticClass: "personal__box" }, [
      _vm._m(0),
      _c("div", { staticClass: "personal__info" }, [
        _c("img", {
          staticClass: "personal__border tl",
          attrs: { src: require("@/assets/border_tl.svg"), alt: "" }
        }),
        _c("img", {
          staticClass: "personal__border tr",
          attrs: { src: require("@/assets/border_tr.svg"), alt: "" }
        }),
        _c("img", {
          staticClass: "personal__border bl",
          attrs: { src: require("@/assets/border_bl.svg"), alt: "" }
        }),
        _c("img", {
          staticClass: "personal__border br",
          attrs: { src: require("@/assets/border_br.svg"), alt: "" }
        }),
        _c("div", { staticClass: "personal__item" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("姓名")]),
          _c("div", { staticClass: "item__input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              attrs: {
                type: "text",
                placeholder: "請輸入姓名",
                maxlength: "20"
              },
              domProps: { value: _vm.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value
                }
              }
            }),
            !_vm.nameCheck
              ? _c("img", {
                  staticClass: "input__error",
                  attrs: { src: require("@/assets/icon_wrong.svg"), alt: "" }
                })
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "personal__item" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("性別")]),
          _c("div", { staticClass: "item__sex" }, [
            _c(
              "div",
              {
                staticClass: "sex-box male",
                class: { activeSex: _vm.activeSex == "male" },
                on: { click: _vm.selectMale }
              },
              [_vm._v("\n            男生\n          ")]
            ),
            _c(
              "div",
              {
                staticClass: "sex-box female",
                class: { activeSex: _vm.activeSex == "female" },
                on: { click: _vm.selectFemale }
              },
              [_vm._v("\n            女生\n          ")]
            )
          ])
        ]),
        _c("div", { staticClass: "personal__item" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("出生日期")]),
          _c(
            "div",
            {
              staticClass: "item__input",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.dateModal = true
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.date))]),
              _c(
                "v-dialog",
                {
                  ref: "dialog",
                  attrs: {
                    "return-value": _vm.date,
                    width: "290px",
                    persistent: ""
                  },
                  on: {
                    "update:returnValue": function($event) {
                      _vm.date = $event
                    },
                    "update:return-value": function($event) {
                      _vm.date = $event
                    }
                  },
                  model: {
                    value: _vm.dateModal,
                    callback: function($$v) {
                      _vm.dateModal = $$v
                    },
                    expression: "dateModal"
                  }
                },
                [
                  _c(
                    "v-date-picker",
                    {
                      staticClass: "picker date-picker",
                      attrs: {
                        "first-day-of-week": 0,
                        locale: "zh-cn",
                        scrollable: "",
                        "header-color": "rgba(217, 217, 217, 0.25)",
                        color: "primary",
                        max: "2400-12-31",
                        min: "0676-01-01"
                      },
                      model: {
                        value: _vm.date,
                        callback: function($$v) {
                          _vm.date = $$v
                        },
                        expression: "date"
                      }
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "error" },
                          on: {
                            click: function($event) {
                              _vm.dateModal = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.dialog.save(_vm.date)
                            }
                          }
                        },
                        [_vm._v("確認")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "personal__item" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("出生時間")]),
          _c("div", { staticClass: "time-wrap" }, [
            _c(
              "div",
              {
                staticClass: "item__input",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.timeModal = true
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.time))]),
                _c(
                  "v-dialog",
                  {
                    ref: "dialog2",
                    attrs: {
                      "return-value": _vm.time,
                      width: "290px",
                      persistent: ""
                    },
                    on: {
                      "update:returnValue": function($event) {
                        _vm.time = $event
                      },
                      "update:return-value": function($event) {
                        _vm.time = $event
                      }
                    },
                    model: {
                      value: _vm.timeModal,
                      callback: function($$v) {
                        _vm.timeModal = $$v
                      },
                      expression: "timeModal"
                    }
                  },
                  [
                    _vm.timeModal
                      ? _c(
                          "v-time-picker",
                          {
                            staticClass: "picker time-picker",
                            attrs: {
                              scrollable: "",
                              "ampm-in-title": "",
                              "header-color": "rgba(217, 217, 217, 0.25)",
                              color: "primary"
                            },
                            model: {
                              value: _vm.time,
                              callback: function($$v) {
                                _vm.time = $$v
                              },
                              expression: "time"
                            }
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function($event) {
                                    _vm.timeModal = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.dialog2.save(_vm.time)
                                  }
                                }
                              },
                              [_vm._v("確認")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "label",
              { staticClass: "checkbox-wrap", attrs: { id: "check" } },
              [
                _vm._v("\n            我不知道\n            "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.IDontKnow,
                      expression: "IDontKnow"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.IDontKnow)
                      ? _vm._i(_vm.IDontKnow, null) > -1
                      : _vm.IDontKnow
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.IDontKnow,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.IDontKnow = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.IDontKnow = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.IDontKnow = $$c
                      }
                    }
                  }
                }),
                _c("span", { staticClass: "checkmark" })
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "personal__item" }, [
          _vm._m(1),
          _c("div", { staticClass: "item__sex" }, [
            _c(
              "div",
              {
                staticClass: "sex-box male",
                class: { activeSex: _vm.IsDaylight },
                on: {
                  click: function($event) {
                    _vm.IsDaylight = true
                    _vm.DaylightNotice = true
                  }
                }
              },
              [
                _vm._v("\n            是\n            "),
                _vm.DaylightNotice
                  ? _c("div", { staticClass: "daylight" }, [
                      _vm._v("\n              系統將自動"),
                      _c("br"),
                      _vm._v("\n              以日光節約時間測算\n            ")
                    ])
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "sex-box female",
                class: { activeSex: !_vm.IsDaylight },
                on: {
                  click: function($event) {
                    _vm.IsDaylight = false
                    _vm.DaylightNotice = false
                  }
                }
              },
              [_vm._v("\n            否\n          ")]
            )
          ])
        ]),
        _c("div", { staticClass: "personal__item" }, [
          _c("span", { staticClass: "item__title" }, [_vm._v("出生地點")]),
          _c(
            "div",
            { staticClass: "item__input" },
            [
              _c("v-autocomplete", {
                staticClass: "select",
                attrs: {
                  items: _vm.locationItems,
                  label: "請選擇或輸入出生地點",
                  "background-color": "transparent",
                  solo: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.location,
                  callback: function($$v) {
                    _vm.location = $$v
                  },
                  expression: "location"
                }
              })
            ],
            1
          )
        ]),
        _vm.showOtherPlace
          ? _c("div", { staticClass: "personal__item" }, [
              _c("span", { staticClass: "item__title otherPlace" }, [
                _vm._v("出生國家")
              ]),
              _c(
                "div",
                { staticClass: "item__input" },
                [
                  _c("v-autocomplete", {
                    staticClass: "select",
                    attrs: {
                      items: _vm.$CountryCode,
                      "item-text": "ChineseName",
                      "item-value": "Code",
                      label: "請選擇或輸入出生國家",
                      "background-color": "transparent",
                      solo: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.CountryCode,
                      callback: function($$v) {
                        _vm.CountryCode = $$v
                      },
                      expression: "CountryCode"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.showOtherPlace
          ? _c("div", { staticClass: "personal__item" }, [
              _c("span", { staticClass: "item__title otherPlace" }, [
                _vm._v("出生州省")
              ]),
              _c("div", { staticClass: "item__input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.State,
                      expression: "State"
                    }
                  ],
                  attrs: {
                    type: "text",
                    value: "",
                    placeholder: "請輸入州或省，例如 New York"
                  },
                  domProps: { value: _vm.State },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.State = $event.target.value
                    }
                  }
                })
              ])
            ])
          : _vm._e(),
        _vm.showOtherPlace
          ? _c("div", { staticClass: "personal__item" }, [
              _c("span", { staticClass: "item__title otherPlace" }, [
                _vm._v("出生城市")
              ]),
              _c("div", { staticClass: "item__input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.City,
                      expression: "City"
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder: "請輸入城市，例如 Buffalo"
                  },
                  domProps: { value: _vm.City },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.City = $event.target.value
                    }
                  }
                }),
                !_vm.cityCheck
                  ? _c("img", {
                      staticClass: "input__error",
                      attrs: {
                        src: require("@/assets/icon_wrong.svg"),
                        alt: ""
                      }
                    })
                  : _vm._e(),
                _vm.showOtherPlace
                  ? _c("div", { staticClass: "notice_inputEnglish" }, [
                      _vm._v(
                        "\n            州和城市輸入英文可提高準確度。\n          "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "personal__btns" }, [
        _c(
          "div",
          { staticClass: "personal__cancel", on: { click: _vm.cancel } },
          [_vm._v("取消")]
        ),
        _c(
          "div",
          {
            staticClass: "personal__save",
            class: { notReady: _vm.fileNotApply },
            on: { click: _vm.createFriend }
          },
          [_vm._v("\n        新增好友\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "personal__header" }, [
      _c("div", { staticClass: "personal__title" }, [_vm._v("新增好友")]),
      _c("div", { staticClass: "personal__subtitle" }, [
        _vm._v("\n        請輸入您的個人資料，獲取專屬個人星盤。\n      ")
      ]),
      _c("div", { staticClass: "personal__notice" }, [
        _vm._v("\n        提醒您，時間填寫越準確，測算結果越精準哦！\n      ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "item__title" }, [
      _vm._v("日光節約時間\n          "),
      _c("img", { attrs: { src: require("@/assets/日光節約icon.svg") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }